/* eslint-disable @typescript-eslint/no-explicit-any */
import { AUTH_ERROR_MESSAGE, GRAPHQL_API_URL, LOGIN_URL } from '@/constants';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getCookieByName, deleteCookieByName } from '@/utils/cookies';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookieByName('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : ''
    }
  };
});

type ResponseType = {
  message?: string[];
};

const isResponseType = (response: any): response is ResponseType => {
  return response && Array.isArray(response.message);
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      console.error('GraphQL Error:', extensions);
      const response = extensions?.response;
      if (
        isResponseType(response) &&
        response.message?.[0]?.includes(AUTH_ERROR_MESSAGE)
      ) {
        deleteCookieByName('token');
        window.location.href = LOGIN_URL;
      }
    });
  }

  if (networkError) {
    console.error('Network Error:', networkError);
  }
});

// Apollo Client Setup
const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
