import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // Use localStorage for testing
import { persistReducer } from 'redux-persist';

import appReducer from './App/reducer';
import userReducer from './User/reducer';
import orderReducer from './Order/reducer';
import cartReducer from './Cart/reducer';

const appPersistConfig = {
  key: 'app',
  storage
};

const userPersistConfig = {
  key: 'user',
  storage
};

const orderPersistConfig = {
  key: 'order',
  storage
};

const cartPersistConfig = {
  key: 'cart',
  storage
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  user: persistReducer(userPersistConfig, userReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
  cart: persistReducer(cartPersistConfig, cartReducer)
});

export default rootReducer;
