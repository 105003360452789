import { useEffect } from 'react';

const useScrollToTop = () => {
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', handleScrollToTop);
    window.addEventListener('hashchange', handleScrollToTop);

    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function (...args) {
      handleScrollToTop();
      return originalPushState.apply(this, args);
    };

    history.replaceState = function (...args) {
      handleScrollToTop();
      return originalReplaceState.apply(this, args);
    };

    return () => {
      window.removeEventListener('popstate', handleScrollToTop);
      window.removeEventListener('hashchange', handleScrollToTop);
      history.pushState = originalPushState;
      history.replaceState = originalReplaceState;
    };
  }, []);
};

export default useScrollToTop;
